// extracted by mini-css-extract-plugin
export var feed__item = "pub-module--feed__item--2H1uf";
export var feed__itemTitle = "pub-module--feed__item-title--2XgAX";
export var feed__itemTitleLink = "pub-module--feed__item-title-link--2giU6";
export var feed__itemDescription = "pub-module--feed__item-description--1hCtZ";
export var feed__itemAuthor = "pub-module--feed__item-author--tHsf7";
export var feed__itemMetaTime = "pub-module--feed__item-meta-time--MOWL3";
export var feed__itemMetaDivider = "pub-module--feed__item-meta-divider--Wgz_X";
export var feed__itemMetaLink = "pub-module--feed__item-meta-link--F3KEE";
export var feed__itemMetaCategoryLink = "pub-module--feed__item-meta-category-link--VjJeY";
export var feed__itemReadmore = "pub-module--feed__item-readmore--3BM0C";